import { type SystemStatus } from '@xrpl/ai-core';
import useSWR from 'swr';

import { type MarkpromptOptions } from './types.js';
import { fetcher } from './utils.js';

export default function useSystemStatus(
  projectKey: string,
  options: MarkpromptOptions['prompt'] | MarkpromptOptions['chat'],
): {
  status: SystemStatus;
} {
  const apiUrl = options?.apiUrl || 'https://api.ai.xrpl.org';
  const basePath = new URL(apiUrl).origin || '';
  const { data: status } = useSWR(
    `${basePath}/api/status?` + new URLSearchParams({ id: projectKey }),
    fetcher<SystemStatus>,
    {
      refreshInterval: 10 * 60 * 1000,
    },
  );

  return { status: status || 'downtime' };
}
