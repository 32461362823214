import { AccessibleIcon } from '@radix-ui/react-accessible-icon';
import type { PromptFeedback } from '@xrpl/ai-core';
import React, {
  useState,
  type ReactElement,
  type ComponentPropsWithoutRef,
  useEffect,
  useCallback,
} from 'react';

import type { UseFeedbackResult } from './useFeedback.js';
import Button from '../components/ui/Button.js';
import { DEFAULT_MARKPROMPT_OPTIONS } from '../constants.js';
import { ThumbsDownIcon, ThumbsUpIcon } from '../icons.js';
import type { MarkpromptOptions } from '../types.js';

interface FeedbackProps extends ComponentPropsWithoutRef<'aside'> {
  heading?: string;
  submitFeedback: UseFeedbackResult['submitFeedback'];
  abortFeedbackRequest: UseFeedbackResult['abort'];
  variant: 'text' | 'icons';
  promptId?: string;
  feedbackOptions?: MarkpromptOptions['feedback'];
}

export function Feedback(props: FeedbackProps): ReactElement {
  const {
    heading = DEFAULT_MARKPROMPT_OPTIONS.feedback!.heading,
    submitFeedback,
    abortFeedbackRequest,
    variant,
    promptId,
    feedbackOptions,
    ...asideProps
  } = props;

  let extOpts:
    | NonNullable<MarkpromptOptions['feedback']>['extended']
    | undefined;

  if (feedbackOptions) {
    extOpts = feedbackOptions.extended;
  }

  const [feedback, setFeedback] = useState<PromptFeedback>();
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);
  const [radioSubmissionSuccess, setRadioSubmissionSuccess] = useState(false);
  const [textSubmissionSuccess, setTextSubmissionSuccess] = useState(false);

  async function handleFeedback(
    feedback: PromptFeedback,
    callback?: () => void,
  ): Promise<void> {
    setFeedbackSubmitting(true);
    setFeedback(feedback);
    try {
      await submitFeedback(feedback, promptId);
      if (callback) {
        callback();
      }
    } finally {
      setFeedbackSubmitting(false);
    }
  }

  // Allow cmd+enter or ctrl+enter to submit feedback
  const handleTextInputKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!feedback?.textFeedback) {
        return;
      }
      // Check if the user pressed Cmd (for Mac) or Ctrl (for Windows/Linux) key
      const isCmdOrCtrlPressed = e.metaKey || e.ctrlKey;

      // Check if the user pressed the Enter key (keycode 13)
      if (e.key === 'Enter') {
        // Check if both Cmd/Ctrl and Enter are pressed simultaneously
        if (isCmdOrCtrlPressed && feedback) {
          e.preventDefault();
          e.stopPropagation();
          setFeedbackSubmitting(true);
          await submitFeedback(feedback, promptId);
          setFeedbackSubmitting(false);
          setTextSubmissionSuccess(true);
        }
      }
    },
    [feedback, submitFeedback, promptId],
  );

  useEffect(() => {
    // Abort feedback request on unmount
    return () => abortFeedbackRequest();
  }, [abortFeedbackRequest]);

  return (
    <aside {...asideProps} data-variant={variant}>
      <h3>{heading}</h3>
      <div className="MarkpromptFeedbackButtons">
        <button
          disabled={feedbackSubmitting}
          className="MarkpromptGhostThumbButton"
          onClick={() => handleFeedback({ vote: '1' })}
          data-active={feedback?.vote === '1'}
        >
          {variant === 'text' && 'Yes'}
          {variant === 'icons' && (
            <AccessibleIcon label="yes">
              <ThumbsUpIcon width={16} height={16} strokeWidth={2} />
            </AccessibleIcon>
          )}
        </button>
        <button
          disabled={feedbackSubmitting}
          className="MarkpromptGhostThumbButton"
          onClick={() => handleFeedback({ vote: '-1' })}
          data-active={feedback?.vote === '-1'}
          style={{ animationDelay: '100ms' }}
        >
          {variant === 'text' && 'No'}
          {variant === 'icons' && (
            <AccessibleIcon label="no">
              <ThumbsDownIcon width={16} height={16} />
            </AccessibleIcon>
          )}
        </button>
      </div>
      {extOpts && feedback?.vote && (
        <div className="MarkpromptExtendedFeedback">
          <h3>{extOpts.extendedLabel}</h3>
          {extOpts.choices?.map((choice, index) => (
            <label key={index} className="MarkpromptExtendedFeedbackLabel">
              <input
                className="MarkpromptExtendedFeedbackRadio"
                disabled={feedbackSubmitting}
                type="radio"
                name="feedback-choice"
                value={choice}
                data-active={
                  feedback.multiChoiceFeedback === `${index + 1}` &&
                  radioSubmissionSuccess
                }
                onChange={() =>
                  handleFeedback(
                    {
                      ...(feedback as PromptFeedback),
                      multiChoiceFeedback: `${index + 1}`,
                    },
                    () => setRadioSubmissionSuccess(true),
                  )
                }
              />
              {choice}
            </label>
          ))}
          <input
            className="MarkpromptExtendedFeedbackTextInput"
            disabled={feedbackSubmitting}
            type="text"
            data-active={textSubmissionSuccess}
            placeholder={extOpts.additionalPlaceholder}
            onKeyDown={handleTextInputKeyPress}
            onChange={(e) =>
              setFeedback({
                ...(feedback as PromptFeedback),
                textFeedback: e.target.value,
              })
            }
          />
          <Button
            loading={feedbackSubmitting}
            disabled={feedbackSubmitting}
            onClick={() =>
              handleFeedback(feedback as PromptFeedback, () => {
                if (feedback.multiChoiceFeedback) {
                  setRadioSubmissionSuccess(true);
                }

                if (feedback.textFeedback) {
                  setTextSubmissionSuccess(true);
                }
              })
            }
          >
            {feedbackSubmitting
              ? extOpts.extendedButtonLoadingText
              : extOpts.extendedButtonText}
          </Button>
        </div>
      )}
    </aside>
  );
}
