import { fromMarkdown } from 'mdast-util-from-markdown';
import { toString } from 'mdast-util-to-string';

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null;
}

export function markdownToString(markdown = '', maxLength = 200): string {
  const tree = fromMarkdown(markdown);
  const string = toString(tree);

  return string.length > maxLength ? `${string.slice(0, maxLength)}…` : string;
}

interface SWRError extends Error {
  status: number;
  info: any;
}

export const fetcher = async <T = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> => {
  const res = await fetch(input, init);
  return getResponseOrThrow(res);
};

export const getResponseOrThrow = async <T>(res: Response): Promise<T> => {
  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as SWRError;
      error.name = json.name;
      error.status = res.status;
      error.info = json;
      throw error;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
  return res.json();
};
