import clsx from 'clsx';
import React, { type ReactElement } from 'react';

import type { PromptLoadingState } from './usePrompt.js';
import type { ChatLoadingState } from '../chat/store.js';
import * as BaseMarkprompt from '../primitives/headless.js';
import type { MarkpromptOptions } from '../types.js';

interface CaretProps {
  answer: string;
}

export function Caret(props: CaretProps): ReactElement | null {
  const { answer } = props;

  if (answer) {
    return null;
  }

  return <span className="MarkpromptCaret" />;
}

interface AnswerProps {
  className?: string;
  answer: string;
  state: PromptLoadingState | ChatLoadingState;
  supportOptions?: MarkpromptOptions['support'];
}

export function Answer(props: AnswerProps): ReactElement {
  const { answer, className, state, supportOptions } = props;

  const DisclaimerComponent = supportOptions?.disclaimer?.content;

  return (
    <div
      className={clsx('MarkpromptAnswer', className)}
      aria-describedby="markprompt-progressbar"
      aria-busy={state === 'preload' || state === 'streaming-answer'}
      aria-live="polite"
    >
      {supportOptions?.disclaimer?.enabled && (
        <p className="MarkpromptDisclaimer">
          {typeof DisclaimerComponent === 'string' || !DisclaimerComponent ? (
            DisclaimerComponent
          ) : (
            <DisclaimerComponent />
          )}
        </p>
      )}
      <Caret answer={answer} />
      <BaseMarkprompt.Answer answer={answer} />
    </div>
  );
}
