import type { MarkpromptOptions } from '@xrpl/ai-react';

import { markprompt } from './index.js';

declare global {
  interface Window {
    markprompt?: {
      projectKey: string;
      container?: HTMLElement | string;
      options?: MarkpromptOptions;
    };
  }
}

if (!window.markprompt) {
  throw new Error(
    'Markprompt configuration not found on window. See: https://ai.xrpl.org/docs#script-tag',
  );
}

let { container } = window.markprompt;

if (!container) {
  container = document.createElement('div');
  container.id = 'xrplai';
  document.body.appendChild(container);
}

const { projectKey, options } = window.markprompt;

if (!projectKey) {
  throw new Error(
    'XRPL AI project key not found on window. Find your project key in the project settings on the XRPL AI backend.',
  );
}

markprompt(projectKey, container, options);

export {};
